import apiClient from "@/api/apiClient";

export const getAnalytics = async (filters) => {
  const result = await apiClient.get("/api/analytics", {
    params: { ...filters, state: "ARCHIVE" },
  });
  return result.data;
};

export const getKaspiPayAnalytics = async (filters) => {
  const result = await apiClient.get("/api/kaspi-pay/analytics/", {
    params: { ...filters },
  });
  return result.data;
};

export const importKaspiPayAnalytics = async (file_url) => {
  const result = await apiClient.post("/api/kaspi-pay/analytics/", {
    file_url,
  });
  return result.data;
};

export const importJusanAnalytics = async (file_url) => {
  const result = await apiClient.post("/api/jusan/analytics/", {
    file_url,
  });
  return result.data;
};

export const importAnalytics = async (file_url) => {
  const result = await apiClient.post("/api/analytics", {
    file_url,
  });
  return result.data;
};
