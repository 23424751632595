var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex items-center justify-between mb-5"},[_c('div',{staticClass:"font-semibold text-lg"},[_vm._v("Sulpak ("+_vm._s(_vm.total)+")")]),_c('el-upload',{attrs:{"action":_vm.uploadUri,"headers":_vm.headers,"show-file-list":false,"on-success":_vm.handleUploadSuccess,"before-upload":_vm.handleBeforeUpload,"accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel","data":{ type: 'file' }}},[_c('el-button',{ref:"upload",attrs:{"size":"small","disabled":_vm.uploadLoading,"plain":"","type":"warning"}},[_vm._v(" Загрузить прайс ")])],1)],1),_c('el-table',{attrs:{"data":_vm.products,"border":"","size":"small","row-class-name":_vm.tableRowClassName},on:{"row-click":_vm.openProductDetails}},[_c('el-table-column',{attrs:{"type":"index","width":"50","align":"center","label":"N"}}),_c('el-table-column',{attrs:{"label":"Бренд","prop":"brand","width":"100"}}),_c('el-table-column',{attrs:{"label":"Название","prop":"name"}}),_c('el-table-column',{attrs:{"label":"Кол-во","prop":"quantity","width":"100","align":"right"}}),_c('el-table-column',{attrs:{"label":"Цена","prop":"dealer_price","align":"right","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.numberFormat(row.dealer_price))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Цена в каспи","prop":"kaspi_price","align":"right","width":"150"}}),_c('el-table-column',{attrs:{"label":"Прибыль","prop":"kaspi_price","align":"right","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.numberFormat(row.kaspi_price * 0.88 - row.dealer_price))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Артикул","prop":"kaspi_sku","width":"100"}}),_c('el-table-column',{attrs:{"label":"Verified","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949","active-value":true,"inactive-value":false,"disabled":""},model:{value:(row.verified),callback:function ($$v) {_vm.$set(row, "verified", $$v)},expression:"row.verified"}})]}}])})],1),_c('el-pagination',{staticClass:"text-center my-5",attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"page-size":_vm.filters.limit,"current-page":_vm.filters.page},on:{"update:currentPage":function($event){return _vm.$set(_vm.filters, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.filters, "page", $event)},"current-change":function () { return _vm.getProducts(); }}}),_c('el-drawer',{attrs:{"size":"800px","visible":!!_vm.selectedProduct,"before-close":function () { return (_vm.selectedProduct = null); },"with-header":false}},[(_vm.selectedProduct)?[_c('header',{staticClass:"bg-gray-50 border-0 border-b border-solid border-gray-200 p-5 py-3.5 mb-5 font-semibold leading-5 text-lg"},[_c('div',[_vm._v(_vm._s(_vm.selectedProduct.brand))]),_c('div',[_vm._v(_vm._s(_vm.selectedProduct.name))])]),_c('el-button',{on:{"click":_vm.linkProducts}},[_vm._v("save")]),(_vm.selectedProduct.options && _vm.selectedProduct.options.products)?_c('el-table',{attrs:{"size":"small","stripe":"","data":_vm.selectedProduct.options.products},on:{"row-click":_vm.selectKaspiProduct}},[_c('el-table-column',{attrs:{"type":"index","width":"50","align":"center"}}),_c('el-table-column',{attrs:{"prop":"title","label":"Название"}}),_c('el-table-column',{attrs:{"prop":"kaspi_sku","label":"Артикул","width":"100"}}),_c('el-table-column',{attrs:{"prop":"price","label":"Цена","align":"right","width":"100"}}),_c('el-table-column',{attrs:{"prop":"price","label":"Выбор","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.kaspi_sku === _vm.selectedProduct.kaspi_sku)?_c('i',{staticClass:"el-icon-circle-check text-green-500"}):_vm._e()]}}],null,false,2672429806)})],1):_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }