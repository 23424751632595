<script>
import LocalStorageService from "@/services/LocalStorageService";
import {
  importSulpak,
  getSulpakProducts,
  linkProducts,
} from "@/api/accounting";
import { numberFormat } from "@/helpers/formatter";
const localStorageService = LocalStorageService.getService();

export default {
  name: "Sulpak",
  data() {
    return {
      total: 0,
      products: [],
      selectedProduct: null,
      filters: { page: 1, limit: 30 },

      uploadLoading: false,
      uploadUri: `${process.env.VUE_APP_DOMAIN}/api/upload`,
    };
  },
  computed: {
    headers() {
      const token = localStorageService.getAccessToken();
      return { Authorization: `Bearer ${token}` };
    },
  },
  async mounted() {
    await this.getProducts();
  },
  methods: {
    async getProducts() {
      const { data, total } = await getSulpakProducts(this.filters);
      this.products = data;
      this.total = total;
    },
    handleBeforeUpload() {
      this.uploadLoading = true;
    },
    async handleUploadSuccess(res) {
      try {
        this.uploadLoading = false;
        const result = await importSulpak(res.url);

        if (result.success) {
          this.$message.success("Успешно");
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
    },
    numberFormat(number) {
      return numberFormat(number);
    },
    openProductDetails(row) {
      this.selectedProduct = row;
    },
    selectKaspiProduct(row) {
      console.log("row", row);
      this.selectedProduct.kaspi_sku = row.kaspi_sku;
      this.selectedProduct.kaspi_price = row.price;
    },
    async linkProducts() {
      const data = await linkProducts(this.selectedProduct.id, {
        action: "link",
        kaspi_sku: this.selectedProduct.kaspi_sku,
        price: this.selectedProduct.kaspi_price,
      });

      console.log("data", data);
    },
    tableRowClassName({ row }) {
      const { dealer_price, kaspi_price } = row;
      if (!kaspi_price) return "";
      const diff = kaspi_price * 0.88 - dealer_price;
      if (diff > 4000 && diff < 5000) {
        return "bg-yellow";
      } else if (diff > 5000) {
        return "bg-green";
      }
      return "";
    },
  },
};
</script>

<template>
  <div>
    <div class="flex items-center justify-between mb-5">
      <div class="font-semibold text-lg">Sulpak ({{ total }})</div>
      <el-upload
        :action="uploadUri"
        :headers="headers"
        :show-file-list="false"
        :on-success="handleUploadSuccess"
        :before-upload="handleBeforeUpload"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        :data="{ type: 'file' }"
      >
        <el-button
          size="small"
          :disabled="uploadLoading"
          ref="upload"
          plain
          type="warning"
        >
          Загрузить прайс
        </el-button>
      </el-upload>
    </div>

    <el-table
      :data="products"
      border
      size="small"
      @row-click="openProductDetails"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        type="index"
        width="50"
        align="center"
        label="N"
      ></el-table-column>
      <el-table-column label="Бренд" prop="brand" width="100"></el-table-column>
      <el-table-column label="Название" prop="name"></el-table-column>
      <el-table-column
        label="Кол-во"
        prop="quantity"
        width="100"
        align="right"
      ></el-table-column>
      <el-table-column
        label="Цена"
        prop="dealer_price"
        align="right"
        width="150"
      >
        <template slot-scope="{ row }">
          {{ numberFormat(row.dealer_price) }}
        </template>
      </el-table-column>
      <el-table-column
        label="Цена в каспи"
        prop="kaspi_price"
        align="right"
        width="150"
      ></el-table-column>
      <el-table-column
        label="Прибыль"
        prop="kaspi_price"
        align="right"
        width="150"
      >
        <template slot-scope="{ row }">
          {{ numberFormat(row.kaspi_price * 0.88 - row.dealer_price) }}
        </template>
      </el-table-column>
      <el-table-column
        label="Артикул"
        prop="kaspi_sku"
        width="100"
      ></el-table-column>
      <el-table-column label="Verified" width="100" align="center">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.verified"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="true"
            :inactive-value="false"
            disabled
          ></el-switch>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="text-center my-5"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="filters.limit"
      :current-page.sync="filters.page"
      @current-change="() => getProducts()"
    >
    </el-pagination>

    <el-drawer
      size="800px"
      :visible="!!selectedProduct"
      :before-close="() => (selectedProduct = null)"
      :with-header="false"
    >
      <template v-if="selectedProduct">
        <header
          class="bg-gray-50 border-0 border-b border-solid border-gray-200 p-5 py-3.5 mb-5 font-semibold leading-5 text-lg"
        >
          <div>{{ selectedProduct.brand }}</div>
          <div>{{ selectedProduct.name }}</div>
        </header>
        <el-button @click="linkProducts">save</el-button>
        <el-table
          v-if="selectedProduct.options && selectedProduct.options.products"
          size="small"
          stripe
          :data="selectedProduct.options.products"
          @row-click="selectKaspiProduct"
        >
          <el-table-column
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column prop="title" label="Название"></el-table-column>
          <el-table-column
            prop="kaspi_sku"
            label="Артикул"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="price"
            label="Цена"
            align="right"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="price"
            label="Выбор"
            align="center"
            width="100"
          >
            <template slot-scope="{ row }">
              <i
                class="el-icon-circle-check text-green-500"
                v-if="row.kaspi_sku === selectedProduct.kaspi_sku"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-drawer>
  </div>
</template>

<style lang="scss">
.bg-green {
  background-color: #f0f9eb !important;
}
.bg-yellow {
  background-color: #fffbe6 !important;
}
</style>
